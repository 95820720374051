jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-back",
			"position-top",
		],
		navbar: {
			add: true,
			title: ""
		},
		slidingSubmenus: false,
		screenReader: {
			aria: true,
			text: true,
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});


	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link-asccesible").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-item-accesible").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-item-accesible").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* webflow */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"dropdown-on-hover","name":"Dropdown on Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"dropdown-on-hover-list-function","name":"Dropdown on Hover - List Function","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"search-button","name":"Search Button","value":{"style":{},"triggers":[{"type":"click","selector":".header-search-wrap","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]},{"type":"click","stepsA":[{"opacity":0.7500000000000001,"transition":"opacity 500ms ease 0ms"}],"stepsB":[{"opacity":0.35000000000000003,"transition":"opacity 500ms ease 0ms"}]}]}}
	]);
	/* eslint-enable */
});

